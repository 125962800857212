@import 'bootstrap/bootstrap-grid';

@font-face {
  font-family: 'Circular Std';
  font-weight: normal;
  font-style: normal;
  src: URL('fonts/circular-std-book.ttf') format('truetype');
}

@font-face {
  font-family: 'Circular Std';
  font-weight: bold;
  font-style: normal;
  src: URL('fonts/circular-std-bold.ttf') format('truetype');
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Circular Std', 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondary;
  letter-spacing: 0.02em;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $secondary;

  &:hover {
    text-decoration: underline;
    color: $primary;
  }
}

h1,
h2,
h3 {
  font-weight: bold;
  margin: 0;
}

p {
  margin: 0;
}

.btn {
  font-size: 1.25rem;
  height: 3rem;
  border-radius: 1.5rem;
  display: inline-flex;
  align-items: center;
  padding: 0 1.5rem;
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;

  @include media-breakpoint-down(xs) {
    font-size: 1rem;
    height: 2.5rem;
    border-radius: 1.25rem;
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;
    transition: background-color ease-in-out 0.3s;

    &:hover {
      background-color: darken($primary, 10);
      text-decoration: none;

      svg {
        translate: 0.25rem 0;
      }
    }
  }

  svg {
    height: 1rem;
    margin-left: 0.75rem;
    transition: translate ease-in-out 0.1s;
  }
}

section,
header,
footer {
  padding: 4rem 0;

  @include media-breakpoint-down(xs) {
    padding: 3rem 0;
  }
}

section + section {
  margin-top: -4rem;

  @include media-breakpoint-down(xs) {
    margin-top: -3rem;
  }
}

header {
  background-color: $primary;

  .logo {
    display: flex;
    justify-content: center;

    img {
      width: 15.625rem;
      vertical-align: top;

      @include media-breakpoint-down(xs) {
        width: 10.25rem;
      }
    }
  }
}

.hero {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    text-align: center;
    color: $primary;
    font-size: 2.25rem;
    line-height: 3rem;

    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .lead {
    margin-top: 2rem;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;

    @include media-breakpoint-down(xs) {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  a {
    margin-top: 2rem;

    @include media-breakpoint-down(xs) {
      margin-top: 1.5rem;
    }
  }
}

.usp {
  padding-right: 1rem;
  padding-left: 1rem;

  h2 {
    text-align: center;
    font-size: 1.875rem;
    line-height: 2.625rem;
    text-align: center;
    color: $primary;
    margin-top: 2rem;
    max-width: 780px;
    margin: 0 auto;

    @include media-breakpoint-down(xs) {
      font-size: 1.3125rem;
      line-height: 1.75rem;
    }
  }

  .usp-header {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(xs) {
      flex-direction: row;
      align-items: center;

      h3 {
        margin-left: 1.5rem;
        margin-top: 0;
      }
    }
  }

  h3 {
    color: $primary;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-top: 2rem;

    @include media-breakpoint-down(xs) {
      font-size: 1.3125rem;
      line-height: 1.75rem;
    }
  }

  p {
    line-height: 1.625rem;
    margin-top: 1.5rem;
    font-size: 1rem;

    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
  }

  .usp-item {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    @include media-breakpoint-down(xs) {
      margin-top: 2rem;
    }

    .usp-image {
      height: 90px;
      width: 90px;

      @include media-breakpoint-down(xs) {
        height: 70px;
        width: 70px;
        text-align: center;
      }

      img {
        max-width: 100%;
        height: 100%;
        vertical-align: top;
        object-fit: contain;
      }
    }
  }
}

footer {
  background-color: #f4f4f4;
  color: $primary;

  .footer {
    max-width: 540px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .vcard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
        display: inline-flex;
        align-items: flex-start;
        width: auto;
      }

      .contact {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(xs) {
          justify-content: center;
          margin-top: 2rem;
        }

        address {
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-style: normal;

          span {
            width: 1rem;
            display: inline-block;
          }

          a {
            color: $primary;
          }
        }
      }

      .logo {
        display: flex;
        justify-content: flex-start;

        @include media-breakpoint-down(xs) {
          justify-content: center;
        }

        img {
          width: 217px;

          @include media-breakpoint-down(xs) {
            width: 164px;
          }
        }
      }
    }
  }
}
